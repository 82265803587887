import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  // this is definitely an antipattern
  openMobileMenu() {
    document.getElementById('mobile-menu').classList.remove('-closed')
  }

  onScroll(event) {
    this.updateStickyClass();
  }

  updateStickyClass() {
    if (window.scrollY > 30) {
      this.element.classList.add("-stuck");
    } else {
      this.element.classList.remove("-stuck");
    }
  }
}
