import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  close(event) {
    event.preventDefault();
    const project = event.target.dataset.project;
    const encoded_notification = event.target.dataset.value;
    // Hide the notification from view
    this.element.classList.add("-hidden");

    // Indicate that this notification should remain hidden for future sessions
    const url = '/dismiss_notification';
    const data = { notification: encoded_notification };

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF_Token': Rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }
}
