import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  initialize() {
  }

  connect() {
    window.addEventListener('message', this.handleMessageEvent);
  }

  disconnect() {
    window.removeEventListener('message', this.handleMessageEvent);
  }

  handleMessageEvent(message) {
    if (message.source !== document.getElementById('arrive-widget').contentWindow) {
      return;
    }

    const url = '/sessions';
    const data = { access_token: encodeURIComponent(message.data.value.access_token), redirect: false };

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF_Token': Rails.csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.text();
    }).then((html) => {
      document.getElementById('primary-header').innerHTML = html
    }).catch((error) => {
      console.error('Error:', error);
    });
  }
}
